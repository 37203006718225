export const dataProcess = [
   {  
      accHeader: 'Adquisición de información',
      accBody: 'Adquisición de información de la empresa a través de un cuestionario con el objetivo de conocer sobre su filosofía, entorno, misión, visión, publico objetivo, productos y otros datos relevantes.',
   },
   {  
      accHeader: 'Análisis',
      accBody: 'Analisis de la información obtenida, busqueda de puntos comunes y diferenciadores con respecto a la competencia, sitentización de los valores y objetivos de la empresa.',
   },
   {  
      accHeader: 'Desarrollo de líneas de diseño',
      accBody: 'Desarrollo de diversos conceptos que simbolicen a la marca y bocetación de los mismos.',
  },
  {  
      accHeader: 'Definición',
      accBody: 'Elección de linea de diseño, ajustes y vectorización final.',
  },
  {  
      accHeader: 'Paleta de color y tipografía',
      accBody: 'Elección definiva de paleta de color para formatos digitales y físicos. Elección de la tipografía del logotipo y el eslogan.',
  },
  {  
      accHeader: 'Versiones',
      accBody: 'Creación de versiones de la marca en distintos formatos y sobre distintos fondos.',
  },
  {  
      accHeader: 'Complementos',
      accBody: 'Diseño de elementos complementarios de la marca. Elección de tipografías para utilización en la papelería, web y resto de elementos pertenecientes a la marca. ',
  },
  {  
      accHeader: 'Manual de marca',
      accBody: 'Creación de manual con las versiones del logo original, positivo, negativo, continuo, horizontal, vertical, mínimo, convivencia, grilla, usos incorrectos, paleta de color, tipografía, elementos complementarios y otros.',
  },
  {  
      accHeader: 'Aplicaciones',
      accBody: 'Diseño de papelería y otros items aplicando el logo y los elementos complementarios de la marca previamente realizados.',
  },
  {  
      accHeader: 'Página Web',
      accBody: 'Creación de página web utlizando elementos de la marca. Elección y adaptación de plantilla en el CMS Wordpress para futura administración del cliente.',
  },
  {  
      accHeader: 'Línea de Ropa',
      accBody: 'Diseño de ropa utizando el isotipo de la marca del artista.',
  },
   
]
   


export const dataItem = [
   {  
      itemTitle: 'Líneas de diseño',
      itemDesc: 'En las lineas de diseño se trabajo sobre varios conceptos basados en las habilidades de Lit Killah, como son la velocidad y la agresividad de las rimas, también se probó con letras urbanas.',
   },
   {  
      itemTitle: 'Concepto isotipo',
      itemDesc: 'Para crear la imagen de la marca se partió del concepto de su nombre, el cual esta formado por la palabra "lit", encendido traducido al español, y por la palabra "killah", que si bien no existe en ningún diccionario hace referencia a asesinar porque contiene la palabra "kill" y es fonéticamente similar a "killer". Como isotipo se decidió crear un personaje que se identifique directamente con el nombre, por lo cual se creó una llama con cara enojada como simbolismo de la furia asesina del fuego pero con un estilo kawaii para no transmitir agresividad y causar simpatía al publico objetivo, que son mayormente preadolescentes y adolescentes. La cara esta formada por formas geométricas simples que la hacen memorable y legible en tamaños pequeños, así como aplicable en múltiples soportes.',
   },
   {  
      itemTitle: 'Construcción',
      itemDesc: 'El isotipo esta construido a base de formas geométricas puras con el objetivo de aumentar su memorabilidad y permitir su aplicación en múltiples materiales. Un círculo pequeño representa una boca apretada en una situación de enojo, los ojos formados por parte de un círculo y un rectángulo girado 45% son clara representación de furia, y la llama, un poco mas compleja, esta formada por la unión de múltiples arcos de distintas circunferencias.',
   },
   {  
      itemTitle: 'Color',
      itemDesc: 'Para representar la marca se escogió el fuccia por ser singular y extravagante. Su mixtura del color rojo y azul hace que se vincule con el artista porque combina conceptos ambiguos como la sensualidad y la espiritualidad, el sentimiento y el entendimiento, el amor y la abstinencia. El fuccia es símbolo de poder debido a que que su pigmento era extremadamente difícil de conseguir y usado por emperadores para ostentar su riqueza y rango social, además de simbolizar la eternidad por su estabilidad bajo los efectos de la luz. Al aplicar el color sobre el isotipo, que es una llama, le otorga originalidad debido a que no es el color naturalmente asociado al fuego.',
   },
   {  
      itemTitle: 'Versiones y variantes de color',
      itemSubtitle: 'Sólida',
      itemDesc: 'Versión original y más usada debido a ser la mas legible en tamaños pequeños y permitir realizar aplicaciones como bordado y repujado.',
   },
   {  
      itemTitle: 'Versiones y variantes de color',
      itemSubtitle: 'Línea',
      itemDesc: 'Le da un atractivo mayor al isotipo y lo asemeja mas aun a la apariencia de una llama. Es la más liviana de las versiones y en la que se destaca mas la gestualidad de la cara.',
   },
   {  
      itemTitle: 'Versiones y variantes de color',
      itemSubtitle: 'Bordeada',
      itemDesc: 'Se asemeja mas a una llama que la versión sólida y aumenta la legibilidad sobre fotografías y tramas debido a que sus bordes funcionan como delimitador con respecto al fondo.',
   },
   {  
      itemTitle: 'Expresiones',
      itemDesc: 'Como complemento de la marca se decidió modificar la expresión del isotipo para formar distintos iconos que puedes ser usados como emojis para comunicar distintos estados de animo del artista.',
   },
   {  
      quoteText: 'El diseño es muy simple, por eso es tan complicado.',
      quoteAutor: 'Paul Rand',
   },

]