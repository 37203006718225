import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ReactFullpage from "@fullpage/react-fullpage"
import { FaChevronDown, FaQuoteRight } from "react-icons/fa"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import Contact from "../../components/Contact"
import MainWorks from "../../components/MainWorks"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import { dataProcess, dataItem } from "../../data/ecocavaData"
import Ticker from "react-ticker"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Projectpage = ({ data }) => {
  const {
    allFile: { edges },
    allStrapiProjects: { nodes: projects },
    strapiProjects: { title, subtitle, description, mission, about, image, preview },
  } = data

  const settingsSlider = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <ReactFullpage
      licenseKey={"YOUR_KEY_HERE"}
      scrollingSpeed={1000}
      render={({ state, fullpageApi }) => {
        return (
          <>
            <header className="section hero section-center">
              <BackgroundImage
                fluid={edges[0].node.childImageSharp.fluid}
                className="hero-cover"
              >
                <div className="title-container text-center">
                  <h1>
                    <span className="text-black text-bold">{title}</span>
                    <span className="h1-subtitle">{subtitle}</span>
                  </h1>
                </div>

                <div
                  className="arrow arrow-down"
                  onClick={() => fullpageApi.moveSectionDown()}
                >
                  <FaChevronDown />
                </div>
              </BackgroundImage>
            </header>

            <div className="section mission">
              <div className="section-container">
                <h2>Misión del projecto</h2>
                <p>{description}</p>
                <ul>
                  <li>
                    <p>Cliente</p>
                    <p>{mission.cliente}</p>
                  </li>
                  <li>
                    <p>Localidad</p>
                    <p>{mission.localidad}</p>
                  </li>
                  <li>
                    <p>Industria</p>
                    <p>{mission.industria}</p>
                  </li>
                  <li>
                    <p>Fecha</p>
                    <p>{mission.fecha}</p>
                  </li>
                </ul>
              </div>
            </div>

            <div className="section company">
              <div className="section-container">
                <h3>Sobre la empresa</h3>
                <p>{about}</p>
                <Img fluid={edges[2].node.childImageSharp.fluid} />
              </div>
            </div>

            <div className="section process">
              <div className="section-container">
                <h2 className="portfolio-item">Proceso</h2>
                <article className="process-text">
                <Accordion>
                    {dataProcess.map(data => {
                      return (
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <h5>{data.accHeader}</h5>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>{data.accBody}</p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      )
                    })}
                  </Accordion>
                </article>
                <article className="process-img">
                  <Img fluid={edges[3].node.childImageSharp.fluid} />
                </article>
              </div>
            </div>


            <div className="section">
              <BackgroundImage
                fluid={edges[1].node.childImageSharp.fluid}
                className="hero-cover"
              >
                <Ticker direction="toRight" speed={13}>
                  {() => <span>MARCA</span>}
                </Ticker>
                <Ticker direction="toLeft" speed={9} >
                  {() => <span>MARCA</span>}
                </Ticker>
                <Ticker direction="toRight" speed={11} >
                  {() => <span>MARCA</span>}
                </Ticker>
                <Ticker direction="toLeft" speed={8} >
                  {() => <h2>MARCA</h2>}
                </Ticker>
                <Ticker direction="toRight" speed={12}>
                  {() => <span>MARCA</span>}
                </Ticker>
                <Ticker direction="toLeft" speed={10}>
                  {() => <span>MARCA</span>}
                </Ticker>
                <Ticker direction="toRight" speed={14} >
                  {() => <span>MARCA</span>}
                </Ticker>
              </BackgroundImage>
            </div>



            <div className="section quote">
              <div className="section-container">
                <div className="quote-container">
                  <FaQuoteRight />
                    <h4>{dataItem[8].quoteText}</h4>
                  <FaQuoteRight />
                  <p>{dataItem[8].quoteAutor}</p>
                </div>
              </div>
            </div>

            <MainWorks
              className="section other-works"
              projects={projects}
              title="Otros trabajos"
            />

            <Contact className="section" />

            <div
              className="arrow arrow-top"
              onClick={() => fullpageApi.moveTo(1)}
            >
              <FaChevronDown />
            </div>
          </>
        )
      }}
    />
  )
}

export const query = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "portfolio/eco-cava" }
      }
      sort: { order: ASC, fields: name }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allStrapiProjects(sort: { order: DESC, fields: id }, filter: {slug: {nin: "eco-cava"}}, limit: 6) {
      nodes {
        id
        description
        slug
        title
        subtitle
        preview {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        stack {
          id
          title
        }
      }
    }
    strapiProjects(slug: { eq: "eco-cava" }) {
      title
      subtitle
      description
      mission {
        cliente
        localidad
        industria
        fecha
      }
      about
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      preview {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

export default Projectpage
